import React from "react";
import * as FaIcons from "react-icons/fa";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import i18n from "i18n-react";

class FooterSidebar extends React.Component{
	constructor(props) {
		super(props);
		this.state = { FooterSidebar: false,
		url: props.url};
	}
	 componentDidMount() {
		if(document.querySelector(".fixed-topCollapse") != null){
			this.state.FooterSidebar = false;
			this.showSidebar();
		}
		setTimeout(()=> {
			let li = document.querySelectorAll("a");
			for(var i = 0; i < li.length; i++){
				if(li[i].innerText == i18n.translate("tasks_title") && this.state.url == "tasks"){
					li[i].classList.add("active");
				}else if(li[i].innerText == i18n.translate("myAccount_title") && this.state.url == "MyAccount"){
					li[i].classList.add("active");
				}else if(li[i].innerText == i18n.translate("administration_title") && this.state.url == "AdministrationMzate"){
					li[i].classList.add("active");
				} 
			} 
		}, 500);
		if(this.state.url == "tasks"){
			document.querySelectorAll(".sidebar2 a")[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "MyAccount"){
			document.querySelectorAll(".sidebar2 a")[3].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[3].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[3].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "AdministrationMzate"){
			document.querySelectorAll(".sidebar2 a")[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[0].style.color = "#8DBE22";
		}else if(this.state.url == "tutorialVideos"){
			document.querySelectorAll(".sidebar2 a")[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[1].classList.add("active");
			document.querySelectorAll(".sidebar2 a")[1].children[0].children[0].style.color = "#8DBE22";
		}   
	 }
	componentDidUpdate() {
		if(this.state.FooterSidebar == true){
			this.state.FooterSidebar = false;
			this.showSidebar();
		}
	}
	showSidebar = () => {
		if(this.state.FooterSidebar){
			document.querySelector(".mzateLogo").classList.remove("displayNone");
			document.querySelector(".mzateCollapseLogo").classList.add("displayNone");
			let span = document.querySelectorAll(".sidebar2 a div span");
			let arraySpan = Array.from(span);
			let arraySpanItem = arraySpan.map((s) =>s.classList.remove("displayNone"));
			document.querySelector(".sidebar2").classList.remove("sidebar2Collapse");
			document.querySelector(".align-items-center").classList.remove("containerNoCollapse");
			document.querySelector(".collapseIcon").classList.remove("displayNone");
			document.querySelector(".noCollapseIcon").classList.add("displayNone");
			document.querySelector(".fixed-top").classList.remove("fixed-topCollapse");
			document.querySelector(".tab-pane").classList.remove("tab-paneCollapse");
			document.querySelector("#collapse").childNodes[1].textContent = i18n.translate("minimize");
			this.state.FooterSidebar = false;
		}else{
			document.querySelector(".mzateLogo").classList.add("displayNone");
			document.querySelector(".mzateCollapseLogo").classList.remove("displayNone");
			let span = document.querySelectorAll(".sidebar2 a div span");
			let arraySpan = Array.from(span);
			let arraySpanItem = arraySpan.map((s) => s.classList.add("displayNone"))
			document.querySelector(".sidebar2").classList.add("sidebar2Collapse");
			document.querySelector(".align-items-center").classList.add("containerNoCollapse");
			document.querySelector(".collapseIcon").classList.add("displayNone");
			document.querySelector(".noCollapseIcon").classList.remove("displayNone");
			document.querySelector("#nav-top").classList.add("nav-topCollase");
			document.querySelector(".fixed-top").classList.add("fixed-topCollapse");
			document.querySelector(".tab-pane").classList.add("tab-paneCollapse");
			document.querySelector("#collapse").childNodes[1].textContent = i18n.translate("maximize");
			this.state.FooterSidebar = true;
		} 
	}
	
	stopPropagation =e=>{
		e.stopPropagation();
	}
	render(){
		return (
			<div className="d-flex w-100 justify-content-start align-items-center containerCollapse" 
			data-tip data-for="collapse" onClick={()=>this.showSidebar()} onMouseOver={this.stopPropagation}>
				<FaIcons.FaAngleDoubleLeft  className="collapseIcon"/>
				<FaIcons.FaAngleDoubleRight className="noCollapseIcon displayNone"/>
				<ReactTooltip id="collapse" place="top" effect="solid">
					{i18n.translate("minimize")} 
				</ReactTooltip>
			</div>
		);
	} 
    
}
    
 export default FooterSidebar;