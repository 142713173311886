import React from "react";

export const TopbarData = (rolesToHaveUser) => {
    
    return usersRoles(rolesToHaveUser);
} 

export const TopbarDataCollapse = (rolesToHaveUser) => {
    
    return  usersRoles(rolesToHaveUser);
}

export const usersRoles = (rolesToHaveUser) => {
    let modules = [];
    let crm = false, geofences = false, visits = false, orders = false, routes = false,
	proVisits = false, proPlaning = false, admin = false, platAdmin = false, platSuport = false,
	platMzaSuport = false, login = false, castadmin = false, accessAccount = false,
	customers = false, supervisor = false, tracking = false, flag = false, task = false;
    let logout = false;
    let logSupport = false;
    let mzateSupport = false;
    let clickWelcomeVideo = false;
    let controlPanel = false;
    let showAdmin = false;
    

    for (let role of rolesToHaveUser) {
        if(role === "ROLE_MP_CRM"){
            crm = true;
        }else if(role === "ROLE_ACCOUNT_ACCESS"){
            accessAccount = true;
        }else if(role === "ROLE_PLATFORM_MZATE_SUPPORT"){
            platMzaSuport = true;
        }else if(role === "ROLE_PLATFORM_ADMIN"){
            platAdmin = true;
        }else if(role === "ROLE_PLATFORM_SUPPORT"){
            platSuport = true;
        }else if(role === "ROLE_MP_GEOFENCING"){
            geofences = true;
        }else if(role === "ROLE_MP_VISITS"){
            visits = true;
        }else if(role === "ROLE_MP_ORDERS"){
            orders = true;
        }else if(role === "ROLE_MP_ROUTES"){
            routes = true;
        }else if(role === "ROLE_MP_PROFESSIONAL_VISITS"){
            proVisits = true;
        }else if(role === "ROLE_MP_PROFESSIONAL_PLANNING"){
            proPlaning = true;
        }else if(role === "ROLE_CMP_ADMIN"){
            admin = true;
        }else if(role === "ROLE_CMP_LOGIN"){
            login = true;
        }else if(role === "ROLE_CMP_CASTADMIN"){
            castadmin = true;
        }else if(role === "ROLE_CMP_CUSTOMERS"){
            customers = true;
        }else if(role === "ROLE_MP_SUPERVISOR"){
            supervisor = true;
        }else if(role === "ROLE_MP_TRACKING"){
            tracking = true;
        }else if(role === "ROLE_MP_TASK"){
            task = true;
        }
    }
    modules.push({title: "tutorial_videos",path: "/Videos",className:"displayNone",id:"tutorialVideos"})
    if(geofences === true){
        modules.push({title: "geofences_title",path: "/Geofences",id:"geofencesPlatform"})
    }
    if(crm === true){
        modules.push({title: "opportunities_title",path: "/Oporttunities",id:"opportunitiesPlatform"})
    }
    if(visits === true){
        modules.push({title: "visits_title",path: "/Visits",id:"visitsPlatform"})
    }
    if(task === true){
        modules.push({title: "tasks_title",path: "/Tareas",})
    }
    if(orders === true){
        modules.push({title: "orders_title",path: "/Orders",id:"ordersPlatform"})
    }
    if(routes === true && (admin === true || supervisor === true)){
        modules.push({title: "routes_title",path: "/Routes",id:"routesPlatform"})
    }
    if(proVisits === true){
        modules.push({title: "professionalVisits_title",path: "/ProfessionalV",id:"professionalVisitsPlatform"})
    }
    if(proPlaning === true && (admin === true || supervisor === true)){
        modules.push({title: "professionalManagement_title",path: "/ManagementP",id:"professionalManagementPlatform"})
    }

    if(castadmin === true  || (controlPanel === true || controlPanel === "true") || 
        ( (platMzaSuport === true || platMzaSuport === "true") && (logSupport === true || logSupport === "true") && (mzateSupport === false || mzateSupport === "false") ) 
        || ( (admin === true || supervisor === true || platAdmin === true) && tracking === false) ){

        modules.push({title: "controlPanel_title",path: "/Panel",id:"controlPanelPlatform"})

    }

    if(admin === true  || castadmin === true || mzateSupport === false || 
        (platMzaSuport === true && (logSupport === true || logSupport === "true") && (controlPanel === false || controlPanel === "false")) || platAdmin === true){
        modules.push({title: "administration_title",path: "/AdministrationMzate",id:"administrationPlatform"});
    }

    if(castadmin === true || (platMzaSuport === true && (logSupport === true || logSupport === "true") && (mzateSupport === true || mzateSupport === "true") && (controlPanel === false || controlPanel === "false")) || platSuport === true || platAdmin === true){
        modules.push({title: "default_support",path: "/Support",id:"supportPlatform"});
    } 
    
    if(admin === true){
        modules.push({title: "myAccount_title",path: "/MyAccount",id:"myAccountPlatform"})
    }

    
    return modules
}

