import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import { TopbarData, TopbarDataCollapse } from "./TopbarData";
import Menus from "./Menus";
import { IconContext } from "react-icons/lib";
import i18n from "i18n-react";
import { useEffect, useState } from "react";
import axios from 'axios';
const url = process.env.REACT_APP_API_URL;

const Nav = styled.div`
    background: #15171c;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    `;

const Topbar = () => {
    const [TopbarDatas, setTopbarData] = useState([]);
    const [TopbarDatasCollapse, setTopbarDataCollapse] = useState([]);
    const [IconCollapseModules, setIconCollapseModules] = useState("");
    const [serviceName, setServiceName] = useState(""); 
    const [userFullname, setUserFullName] = useState("");
    const [ajaxFlag, setAjaxFlag] = useState(false);
    const [platformUrl, setPlatformUrl] = useState("");
    const [pathRoot, setPathRoot] = useState(""); 
    const [token, setToken] = useState("");
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState(""); 
    const [maxElement, setMaxElement] = useState("");
    const [instanceType, setInstanceType] = useState("");  
    const [independent, setIndependent] = useState("");
    const [currentModule, setCurrentModule] = useState("");
    const [defaultCountryCenter, setDefaultCountryCenter] = useState("");
    const [userId, setUserId] = useState("");

    setInterval(() => {
        generateTokenForView();
    }, 600000);

    const generateTokenForView = () => {
        if(username !== "" && password !== ""){
            axios({
                method: 'GET',
                url: url+"session/generateTokenForView?username="+username+"&password="+password,
                headers: {'Authorization': token},
                transformResponse: [(data) => {
                    let token = decodeURIComponent(data);
                    setToken(token);
                    document.querySelector("#token").value = token;
                }]
            })
        } 
        
    } 

    useEffect(() => {
        if(ajaxFlag === false){
            axios.get(url+"globalConfig.json").then(response => {
                setPathRoot(response.data.pathRoot);
                let currentUrl = window.location.href;
                let params = currentUrl.split("?token=");
                let modules = params[0].split("?module=");
                setCurrentModule(modules[1]);
                let token = decodeURIComponent(params[1]);
                setInstanceType(response.data.instanceType);
                setIndependent(response.data.independent);
                setPlatformUrl(response.data.platformUrl);
                setToken(token)
                axios({
                    method: 'GET',
                    url: url+"session/validateTokenForPlatform?token="+token,
                    headers: {'Authorization': token},
                    transformResponse: [(data) => {
                        let maxWidth = document.querySelector("#nav-tab").clientWidth;
                        let maxElement = Math.floor(maxWidth / 150);
                        setMaxElement(maxElement);
                        let result = data.split("|");
                        setUserId(result[0])
                        setUserName(result[1]);
                        setServiceName(result[5]);
                        setUserFullName(result[6]);
                        setDefaultCountryCenter(result[8])
                        setPassword(result[2]);
                        let roles = result[4].split(",");
                        let rolesToHaveUser = roles;
                        setTopbarDataCollapse(TopbarDataCollapse(rolesToHaveUser));
                        setTopbarData(TopbarData(rolesToHaveUser));
                    }]
                })
                setAjaxFlag(true);
            })
        } 
        
    });

    const showUserInfo = () => {
        if(document.querySelector(".dropdown-menu").style.display === "block"){
            document.querySelector(".dropdown-menu").style.display = "none";
        }else{
            document.querySelector(".dropdown-menu").style.display = "block";
        }
    }

    const userPreferences = (e) => {
        if(e.target.id === "indexConfig" || e.target.id == "userConfiguration"){
            window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=userConfigs";
        }else if(e.target.id == "indexTickets" || e.target.id == "userTickets"){
            window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=userTickets";
        }else if(e.target.id == 'tutorialVideos'){
            let currentUrl = window.location.origin;
            window.open(currentUrl+"?module=tutorialVideos?token="+token,'_blank');
        }   
    } 
    return (
        <>
        <IconContext.Provider value={{ color: "#fff" }}>
            <Nav id="nav-top">
                <input type="hidden" id="token" value={token}/>
                <input type="hidden" id="instanceType" value={instanceType}/>
                <input type="hidden" id="independent" value={independent}/>
                <input type="hidden" id="platformUrl" value={platformUrl}/>
                <input type="hidden" id="pathRoot" value={pathRoot}/>
                <input type="hidden" id="currentModule" value={currentModule}/>
                <input type="hidden" id="defaultCountryCenter" value={defaultCountryCenter}/>
                <input type="hidden" id="userFullname" value={userFullname}/>
                <input type="hidden" id="userId" value={userId}/>
                <div className="nav nav-tabs nav-fill fixed-top" id="nav-tab" role="tablist">
                    {TopbarDatas.map((item, index) => {
                        return (
                            <>
                                {index <= maxElement &&
                                    <Menus item={item} index={index} platformUrl={platformUrl} pathRoot={pathRoot} token={token} />
                                }
                            </>
                        );
                    })}
                    {TopbarDatasCollapse.length > maxElement+1 &&
                        <>
                            <li className="nav-item dropdown dropdownModulesCollapse"
                                onMouseEnter={() => setIconCollapseModules("dropdown-modulesCollapseShow")}
                                onMouseLeave={() => setIconCollapseModules("")}
                            >
                                <a>
                                    <FaIcons.FaBars style={{color:'black',fontSize: '35px'}}/>
                                </a>
                                <div className={'dropdown-modulesCollapse '+IconCollapseModules}>
                                    {TopbarDatasCollapse.map((item, index) => {
                                        return(
                                            <>
                                                {index > maxElement &&
                                                    <Menus item={item} index={index} platformUrl={platformUrl} pathRoot={pathRoot} token={token}/>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </li>
                        </>
                    }
                    
                </div>
                <li className="nav-item dropdown" id="nav-user" onClick={showUserInfo}>
                    <a className="nav-link dropdown-toggle" id="nav-usuario-tab" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        <div id="userInfoContainer">
                            <div id="userIcon">
                                <FaIcons.FaUserCircle/>
                            </div>
                        <div id="userInfo">
                            <div id="userName">
                               {i18n.translate("hello")}, {userFullname} 
                            </div>
                            <div id="serviceName">
                                {serviceName} 
                            </div>
                        </div>
                        
                        </div>
                    </a>
                    <div className="dropdown-menu">
                    <a className="dropdown-item" id="indexConfig" onClick={userPreferences}>
                        <FaIcons.FaCog className="noPointerEvents"/>
                        <span id="userConfiguration" className="noPointerEvents">{i18n.translate("user_configuration")}</span>
                    </a>
                    <a className="dropdown-item" id="indexTickets" onClick={userPreferences}>
                        <FaIcons.FaQuestionCircle className="noPointerEvents"/>
                        <span id="userTickets" className="noPointerEvents">{i18n.translate("tickets_title")}</span>
                    </a>
                    <a className="dropdown-item" id="tutorialVideos" onClick={userPreferences}>
                        <FaIcons.FaPlay className="noPointerEvents"/>
                        <span id="userTickets" className="noPointerEvents">{i18n.translate("tutorial_videos")}</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" id="logout" href={platformUrl+pathRoot+"logout"}>
                        <FaIcons.FaSignOutAlt/>
                        <span id="userOut">{i18n.translate("sign_off")} </span>
                    </a>
                    </div>
                </li>
            </Nav>
            
        </IconContext.Provider>
        </>
    );
};
    
export default Topbar;