import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import i18n from "i18n-react";

const SidebarLink = styled(Link)`
display: flex;
color: #e1e9fc;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 60px;
text-decoration: none;
font-size: 18px;

&:hover {
	background: #252831;
	border-left: 4px solid green;
	cursor: pointer;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
`;

const DropdownLink = styled(Link)`
background: #252831;
height: 60px;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
color: #f5f5f5;
font-size: 18px;

&:hover {
	background: green;
	cursor: pointer;
}
`;

const SubMenu = ({ item }) => {

const [subnav, setSubnav] = useState(false);

const showSubnav = () => setSubnav(!subnav);

const handleClick = event => {
	let platformUrl = document.querySelector("#platformUrl").value;
	let pathRoot = document.querySelector("#pathRoot").value;
	let token = document.querySelector("#token").value;
	if(event.target.id == "myAccountInformation"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=myAccountFromAppWeb";
	}else if(event.target.id == "myAccountBilling"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=myAccountFromAppWebBilling";
	}else if(event.target.id == "mzatePlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboard.html?lang=es?module=administrationFromAppWeb";
	}  
	let a = document.querySelectorAll(".sidebar2 a");
	let arrayA = Array.from(a);
  
	let activo = arrayA.find(cv => cv.classList.contains("active"));
	if (activo) {
	  activo.classList.remove("active");
	}
	event.currentTarget.classList.add('active');

	let span = document.querySelectorAll(".sidebar2 a span");
	let arrayS = Array.from(span);

	let sActivo = arrayS.find(s => s.classList.contains("active"));
	if (sActivo) {
		sActivo.classList.remove("active");
	}
	
	event.currentTarget.children[0].children[1].classList.add('active');

	let svg = document.querySelectorAll(".sidebar2 svg");

	for (let item of svg) {
		item.style.color = "rgb(255, 255, 255)";
	} 

	event.target.children[0].children[0].style.color = "#8DBE22";
};

return (
	<>
	<SidebarLink to={item.path}
	onMouseDown={item.subNav && showSubnav} onClick={handleClick} id={item.id}>
		<div>
		{item.icon}
		<SidebarLabel >{i18n.translate(item.title)}</SidebarLabel>
		</div>
		<div>
		{item.subNav && subnav
			? item.iconOpened
			: item.subNav
			? item.iconClosed
			: null}
		</div>
	</SidebarLink>
	{subnav &&
		item.subNav.map((item, index) => {
		return (
			<DropdownLink to={item.path} key={index}>
			{item.icon}
			<SidebarLabel>{i18n.translate(item.title)}</SidebarLabel>
			</DropdownLink>
		);
		})}
	</>
);
};

export default SubMenu;
