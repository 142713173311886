import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Topbar from "./components/Topbar"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Tasks from './pages/Tasks'
// import Administration from './pages/Administration';
import Videos from './pages/Videos';
import AdministrationMzate from './pages/AdministrationMzate';
import i18n from "i18n-react";
import MyAccount from './pages/MyAccount';

let language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

if (language.length > 2) {
  language = language.split("-")[0];
  language = language.split("_")[0];
}

if(language !== "es" && language !== "en"){
  language = "en";
} 

i18n.setTexts(require(`./i18n/`+ language +`.json`))
let currentUrl = window.location.href;
let params = currentUrl.split("?token=");
let module = params[0].split("?module=");
let component = <Tasks language={language}/>;
if(module[1] === 'storage'){
  component = <MyAccount module={module[1]} token={decodeURIComponent(params[1])} />;
}else if(module[1] === 'administrationMzate'){
  component = <AdministrationMzate/>;
}else if(module[1] === 'tutorialVideos'){
  component = <Videos token={decodeURIComponent(params[1])}/>;
}   

function App() {
  return (
    <Router>
      <Topbar/>
      <Routes>
        <Route path="/" exact element={component} />
        <Route path="/Tareas/*" exact element={<Tasks language={language}/>} />
        <Route path="/AdministrationMzate/*" exact element={<AdministrationMzate/>} /> 
        <Route path='/MyAccount/storage' exact element={<MyAccount/>} />
        <Route path="/Videos/*" exact element={<Videos/>} />      
      </Routes>
    </Router>
  );
}

export default App;
