import React from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import DefaultModal from '../../components/DefaultModal';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import DefaultSpinner from "../../components/DefaultSpinner";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";

const url = process.env.REACT_APP_API_URL;

class VideosIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalContent:"",
            instanceType:"",
            instanceTypeDescription:"",
            independent:"",
            castMzate:"",
            videos: [],
            data: [], 
            modules: [],
            isloaded: false,
            spinner:"",
            token: props.token, 
        } 
    } 
    componentDidMount() {
        var helpDtoJson = {
            "title": "",
            "mediaType": "",
            "descriptionCode": "",
            "moduleCode": "",
            "section": "",
            "subSection": "",
            "roleCode": "",
            "isWeb": null
        };
        let token;
        if(!this.state.token){
            this.setState({token: document.querySelector("#token").value});
            token = document.querySelector("#token").value;
        }else{
            token = this.state.token;
            document.querySelector("#token").value = token;
        } 
        axios.get(url+"globalConfig.json").then(response => {
            this.setState({
                instanceType:response.data.instanceType,
                instanceTypeDescription:response.data.instanceTypeDescription,
                independent:response.data.independent,
                castMzate:response.data.castMzate,
                spinner:<DefaultSpinner instanceType={response.data.instanceType} 
                independent={response.data.independent}/>
            });
        })
        this.getVideos(helpDtoJson);
        this.getModules();
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbarT").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 40;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        document.querySelector("#ulSidebar a").style.pointerEvents = 'none';
    }

    handleLoad = () =>{
        setTimeout(()=> {
            if(document.querySelector(".dropdownModulesCollapse") != null){
                document.querySelector(".dropdownModulesCollapse").style.display = "none";
            }
            let modules = document.querySelectorAll(".fixed-top a");
            let modulesA = Array.from(modules);
            modulesA.map((m) =>{
                m.style.display = "none";
            })
            if(modules.length > 0){
                modules[0].classList.remove("displayNone"); 
                modules[0].style.display = "";
                modules[0].classList.add("active");
            }
        }, 400);
    }

    getVideos = (helpDtoJson) => {
        let params = JSON.stringify(helpDtoJson);
        DefaultAjax('').get("helps/getAllHelpList?helpDtoJson="+ encodeURIComponent(params))
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    videos:result,
                    data:result
                });
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    //carga para el usuario
    getModules = () => {
        DefaultAjax('').get("helps/getAllModules?filterForLoggedUser=true")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                let modules = []; 
                modules.push({"value":"","label":i18n.translate("default_show_all")});
                for(var i = 0; i < result.length;i++){
                    modules.push({"value":result[i].code,"label":result[i].description});
                }
                this.setState({
                    modules:modules,
                    spinner: ""
                });
                this.handleLoad();
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 
    
    showVideo = (video) => {
        this.setState({modalContent:""});
        let modalContent;
        let token = document.querySelector("#token").value;
        modalContent = <div className='imgThumbnail' style={{textAlign:'center'}}>
            <video width="100%" height="250" controls controlslist="nodownload" preload="metadata" poster={"data:image/png;base64,"+video.thumbnailBase64}>
                <source id="sourceVideo" src={url+"helps/playVideo?id=" + video.id+"&token="+token}  type=""/>
            </video>
            </div>;
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("default_close"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={video.title} 
            content={modalContent} buttons={buttons} modalClass="modalLargue"/>});
        }, 10);
    } 

    handleChangeSelect = (e,select) => {
        let moduleCode = "";
        let isWeb = null;
        if(select === "modules"){
            moduleCode = e.value;
        }else if(select === "type"){
            if(e.target.value === "true"){
                isWeb = true;
            }else if(e.target.value === "false"){
                isWeb = false;
            } 
        }
        var helpDtoJson = {
            "title": "",
            "mediaType": "",
            "descriptionCode": "",
            "moduleCode": moduleCode,
            "section": "",
            "subSection": "",
            "roleCode": "",
            "isWeb": isWeb
        };
        this.getVideos(helpDtoJson);
    } 

    filterVideos =(e)=> {
        let filterSearch = e.target.value.toLowerCase();
        let searchRes = this.state.data.filter((e) => {
            let title = e.title.toString();
            let descriptionVideo = e.descriptionCode.toString();
            return title.indexOf(filterSearch) !== -1 || descriptionVideo.indexOf(filterSearch) !== -1
        })
        if(e.target.value === ''){
            searchRes = this.state.data;
        }
        this.setState({videos:searchRes});
    } 

    render(){
        return(
            <>
                <br />
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover></ToastContainer>
                {this.state.modalContent}
                <nav className='navbarT' onLoad={this.handleLoad()}>
                    <Row>
                        <Col sm={2} md={2} lg={2} xl={2}>
                            <label>{i18n.translate("default_search")}</label>
                            <input className='form-control inputStandard' type='text' onChange={this.filterVideos}/>
                        </Col>
                        <Col sm={2} md={2} lg={2} xl={2}>
                            <label>{i18n.translate("default_modules")}</label>
                            <Select onChange={(e)=>this.handleChangeSelect(e,'modules')} name="modules" options={this.state.modules}/>
                        </Col>
                        <Col sm={2} md={2} lg={2} xl={2}>
                            <label>{i18n.translate("default_type")}</label>
                            <select name="type" className="form-select selectStandard selectType" onChange={(e)=>this.handleChangeSelect(e,'type')}>
                                <option value="" id="default_all">{i18n.translate("default_all")}</option>
                                <option value="true" id="default_web">{i18n.translate("default_web")}</option>
                                <option value="false" id="default_movil">{i18n.translate("default_movil")}</option>
                            </select>
                        </Col>
                    </Row>
                </nav>
                <br/>
                <div className='bodyContent' id='bodyContent'>
                    {this.state.spinner}
                    <Row>
                        {this.state.videos.length == 0 && <>
                            <Col className="colVideoTutorial" 
                                sm={6} md={6} lg={6} xl={6}>
                                No hay resultados para la busqueda
                            </Col>
                        </>} 
                        {this.state.videos.map((v) =>{
                            return(
                                <Col className="colVideoTutorial" 
                                sm={6} md={6} lg={6} xl={6} key="1" style={{textAlign:'center'}} onClick={() => this.showVideo(v)}>
                                    <div className='videoThumbnail' id={v.id}>
                                        <h5>{v.title}</h5>
                                        <p style={{wordBreak: 'break-all'}}>{v.descriptionCode}</p>
                                        <img src={"data:image/png;base64,"+v.thumbnailBase64} className="videoThumbnailImg"/>
                                        <p style={{position:'relative',margin:'0px',wordBreak: 'break-all',width:'95%'}}>
                                            {v.isWeb ? <FaIcons.FaGlobe style={{color:'#001188',position:'absolute',top:'10px',left:'2px'}}/> : <FaIcons.FaMobileAlt style={{color:'#001188',position:'absolute',top:'10px',left:'2px'}}/>} 
                                            <span style={{position:'relative',top:'8px',left:'15px'}}>{v.moduleDescription} - {v.section} - {v.subSection}</span>
                                        </p>
                                    </div>
                                    <FaIcons.FaPlay className="playIcon" size={30}/>
                                </Col>
                            )
                        })} 
                        <br></br>
                    </Row>
                </div>
            </>
        )
    } 
}  

export default VideosIndex;